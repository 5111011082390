//import '@ionic/react/css/palettes/dark.always.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

import { IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem, IonRow } from "@ionic/react";
import { useState } from "react";
import React from 'react';
import { arrayUnion, db } from '../services/firebase';
import './Search.css';

const API_KEY = "AIzaSyAUCPGVaEv4Fv8CHp8leH7eB-vf2lbBJZ8"

interface Items {
  [key: string]: {
    id: string | { videoId: string };
    snippet: {
      title: string;
      description: string;
      channelTitle: string;
      thumbnails: {
        default: { url: string }
      }
      ;
      thumbnail?: any;
    },
    isSearch: boolean;
    duration: string;
  }
}

type SearchProps = {
  pane: string;
  roomId: string;
  userId: string;
  ownerId: string;
};

// const Search = () => {
const Search: React.FC<SearchProps> = ({ pane, roomId, userId, ownerId }) => {
  const [query, setQuery] = useState('');
  const [result, setResult] = useState<Items | {}>({});

  const handleChange = (e: any) => {
    setQuery((e.target as HTMLInputElement).value)
  }

  const searchVideos = async () => {
    if (!query) return;
    try {
      const searchResponse = await fetch(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=10&q=${query}&type=video&key=${API_KEY}&maxResults=${20}`
      );

      if (!searchResponse.ok) {
        throw new Error("Error fetching search data");
      }

      const searchData = await searchResponse.json();
      const videoIds = searchData.items
        .map((item: any) => item.id.videoId)
        .filter(Boolean)
        .join(",");

      const detailsResponse = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${videoIds}&key=${API_KEY}&maxResults=${20}`
      );

      if (!detailsResponse.ok) {
        throw new Error("Error fetching video details");
      }

      const detailsData = await detailsResponse.json();

      const updatedItems = searchData.items.map((item: any) => {
        const details = detailsData.items.find(
          (detail: any) => detail.id === item.id.videoId
        );
        return {
          ...item,
          isSearch: true,
          duration: details ? details.contentDetails.duration : null,
        };
      });

      setResult(updatedItems);
    } catch (err) {
      console.log(err);
    }
  };

  function formatYouTubeDuration(duration: string) {
    if (duration === 'P0D') {
      return "LIVE"
    }
    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
    const matches = duration.match(regex);
    if (matches) {
      const hours = parseInt(matches[1] || "0", 10);
      const minutes = parseInt(matches[2] || "0", 10);
      const seconds = parseInt(matches[3] || "0", 10);

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
  }


  const linkInput = () => {
    const isYouTubeUrl = (url: string) =>
      // /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}(&[\S]*)?$/.test(url);
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube.com|youtu.be))(\/(watch\?v=|embed\/|v\/)?)([\w\-]+)(\S+)/gm.test(url);

    const isMp4Url = (url: string) => /\.(m3u8)|.(mp4)$/.test(url);
    if (isYouTubeUrl(query)) {
      // const videoId = query.split('v=')[1]?.split('&')[0]; // Extract the video ID
       const videoId = query.split('/').slice(-1)[0].split('?')[0]; // Extract the video ID

      fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY}&part=snippet,id&order=data&maxResults=20`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('Failed to fetch YouTube video data');
          }
        })
        .then((data) => {
          if (data.items.length > 0) {
            const videoData = data.items[0];
            const updatedItem = {
              ...videoData,
              isSearch: false,
              type: 'youtube',
            };
            setResult([updatedItem]);
          } else {
            throw new Error('YouTube video not found');
          }
        })
        .catch((err) => {
          console.error(err);
          setResult({});
        });
    } 
    
     if (isMp4Url(query)) {
      const updatedItem = {
        isSearch: false,
      };
      setResult([updatedItem]);
    } else {
      console.log('Invalid URL');
      setResult({});
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isLink = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]+/.test(query);

    if (isLink || query.endsWith('.m3u8')) {
      linkInput();
    } else {
      searchVideos();
    }
  };
  const handleClick = async (id: string) => {
    let videoUrl;
    if (id === '.m3u8') {
      videoUrl = query
    } else {
      videoUrl = `https://www.youtube.com/watch?v=${id}`
    }

    // if (userId === ownerId) {

      await db.collection('playlists').doc(roomId).update({
        url: videoUrl,
      });

      await db
        .collection('rooms')
        .doc(roomId)
        .update({
          requests: arrayUnion({ createdAt: Date.now(), senderId: userId, data: 0, type: 'change' }),
        });
    // }
  }

  return (
    <IonContent style={{ display: pane === 'search' ? null : 'none' }}>
      <h1 className='search-heading'>Search</h1>
      <div style={{ maxWidth: '1200px', marginInline: 'auto' }}>
        <div>
          <form onSubmit={handleSubmit}>
            <IonItem>
              <IonInput
                id="query"
                name="query"
                placeholder="       Search Youtube Video or Video Link"
                value={query}
                onIonInput={handleChange}
                class="search-input"
              />
            </IonItem>
            <IonButton class='search-btn' type="submit">Search</IonButton>
          </form>
        </div>
        { /* result */}
        <div>
          {
            result &&
              Object.entries(result).length > 0 ? Object.entries(result).map(([id, { snippet = {}, isSearch, duration, ...other }], index) => {
                const { title = '', description = '', channelTitle = '', thumbnails } = snippet;
                return (
                  <IonButton
                    color="light"
                    fill="clear"
                    expand="full"
                    size="small"
                    className="ion-text-start"
                    style={{ textTransform: 'none', height: '100%', margin: 0, '--padding-top': '0px', '--padding-bottom': '0px', '--padding-start': '0px', '--padding-end': '0px', backgroundColor: '#212121', marginBottom: '0.5rem' }}
                    onClick={() => handleClick(query.endsWith('.m3u8') ? '.m3u8' : isSearch && typeof other.id !== 'string' ? other.id.videoId : other.id as string)}
                    key={index}>
                    <IonGrid style={{ padding: 0, width: "100%" }}>
                      <IonRow className='card_container'>
                        <IonCol size="auto" style={{ padding: 0 }} className='img-container'>
                          <img src={thumbnails?.default?.url ?? '/assets/video-placeholder.png'} alt={id} width="200" height="112.5" />
                          {
                            duration &&
                            <span className="video-duration">
                              {formatYouTubeDuration(duration)}
                            </span>
                          }
                        </IonCol>
                        <IonCol className='search-card-content' style={{ padding: '0.5rem 1rem' }}>
                          <h4 style={{ margin: 0 }}>{title ? title : 'Video'}</h4>
                          {description && <p >{description}</p>}
                          {channelTitle && <h5 >{channelTitle}</h5>}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonButton>
                )
              })
              : query !== '' && !query.endsWith('.m3u8') && <p>Search YouTube for "{query}" by pressing enter, or by clicking search.</p>
          }
          {
            Object.keys(result).length === 0
            && <h2 className="ion-text-center ion-margin">No Video Found!</h2>
          }
        </div>
      </div>
    </IonContent >
  )
}

export default Search;
